const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/5.png",
    text: "5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/6.png",
    text: "6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/7.png",
    text: "7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/8.png",
    text: "8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/9.png",
    text: "9",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/10.png",
    text: "10",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorgujranwala/11.png",
    text: "11",
  },
];

export default data;
